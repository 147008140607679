<template>
    <section class="slider-testimonios -mentees">


        <div class="container">
            <h2 class="subtitle">Testimonios de mentores y mentees</h2>
            
            <slick
                ref="slick"
                :options="slickOptions">

                <div class="slide">
                    <blockquote><h5>Fue una experiencia muy enriquecedora. Al final, estás contribuyendo a esbozar el inicio de la carrera profesional de otras personas. Confían en ti sus dudas e incertidumbres y juntos trabajáis en encontrar un plan de futuro que encaje con sus motivaciones y aspiraciones, pero además que tenga sentido y encaje en el mundo laboral actual. Es una gran responsabilidad y, por ello, es tan gratificante. Además, como mentor, te ayuda a comprender mejor las preocupaciones y necesidades de los recién graduados en su ‘salto’ al mundo profesional. Lo que considero que será muy importante para atraer el talento del futuro. Sin duda, es una experiencia que se la recomiendo a cualquier persona, de cualquier edad o sector.
                    </h5></blockquote>
                    <div class="persona column-center">
                        <img src="/img/rosa-puentes.jpg" alt="Rosa Puentes">
                        <h3>Rosa Puentes</h3>
                        <p><strong>Mentora</strong></p>
                        <p>Experta en Hidrógeno y Calidad de gas</p>
                        <p>ENTSOG - European Network of Transmission System Operators for Gas</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>Para mí el Programa Mentoring Alumni UC3M ha sido la oportunidad de conocer a Álvaro, un profesional apasionado de su trabajo que, con mucha amabilidad, me ha ayudado a definir mis expectativas laborales, a identificar oportunidades, a enriquecer mi CV y a encarar el proceso de búsqueda de empleo de una forma más exitosa. En definitiva, una experiencia verdaderamente buena para hacer networking, aprender, ampliar visiones y conocer personas que han marcado mi trayectoria académica.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/laura-ferre.jpg" alt="Laura Ferre Sanjuán">
                        <h3>Laura Ferre Sanjuán</h3>
                        <p><strong>Mentee</strong></p>
                        <p>Máster Universitario en Geopolítica y Estudios Estratégicos</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>Formar parte del Programa de Mentoring Alumni UC3M como mentor es una experiencia muy enriquecedora. Poder ayudar a alumnos cercanos a incorporarse al mercado laboral y resolver desde las inquietudes más básicas a alguna más compleja, me aporta mucho valor personalmente. Me hubiera gustado tener ese apoyo cuando yo estaba en su mismo momento.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/sergio-campos.jpg" alt="Sergio Campos">
                        <h3>Sergio Campos García</h3>
                        <p><strong>Mentor</strong></p>
                        <p>Manager Transformación Digital</p>
                        <p>BANKIA</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>Ha sido una gran experiencia que recomendaría a todo el mundo. Mi tutor  me ha ayudado a encontrar mi camino en el mundo laboral que me espera tras la universidad, transmitiéndome su experiencia y consejos. Es genial contar con gente comprometida que se vuelca en actividades tan enriquecedoras. ¡Me encantaría participar como mentora en el futuro!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/ana-ahumada.jpg" alt="Ana Ahumada Sanz">
                        <h3>Ana Ahumada Sanz</h3>
                        <p><strong>Mentee</strong></p>
                        <p>Doble Grado en Derecho y Administración de Empresas</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Gracias al Programa Mentoring he tenido la oportunidad de estar en contacto con profesionales, que además de orientarme y aconsejarme, me han inspirado y motivado tanto a nivel personal como profesional. Han transformado mi incertidumbre respecto a “¿y después de graduarme qué?” en objetivos, metas a alcanzar. 
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/georgiana-livia.jpg" alt="Georgiana Livia Cruceanu">
                        <h3>Georgiana Livia Cruceanu</h3>
                        <p><strong>Mentee</strong></p>
                        <p>Grado en Sociología</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>El Programa de Mentoring ayuda al mentor a hacer un ejercicio de análisis sobre la evolución personal para poder transmitirle al mentee toda la experiencia necesaria para dar el salto al mundo laboral.<br>100% recomendado.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/david-rubio.jpg" alt="David Rubio Vidal">
                        <h3>David Rubio Vidal</h3>
                        <p><strong>Mentor</strong></p>
                        <p>Senior Software Engineer in Test</p>
                        <p>DAZN</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>Ha sido un placer para mí poder compartir mi experiencia laboral con una alumna de la UC3M que está interesada en trabajar en Organismos Internacionales. ¡Espero que mis orientaciones le sean útiles y que logre todos sus objetivos profesionales!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/nidia-calleros.jpg" alt="Nidia Calleros">
                        <h3>Nidia Calleros</h3>
                        <p><strong>Mentora</strong></p>
                        <p>Abogada experta en derechos humanos</p>
                        <p>LEECP - Liga Española de la Educación y la Cultura Popular</p>
                    </div>
                </div>

                <div class="slide">
                    <h5>Gracias al Programa Mentoring por haberme puesto en contacto con un gran mentor, de mi mismo sector profesional, y que me ha ayudado en gran medida a conseguir oportunidades laborales, mediante la mejora de mi CV y mi recomendación a distintas empresas. Mi mentor siempre ha estado disponible para resolver cualquiera de mis dudas y, su ayuda, me ha servido para finalizar el máster con un contrato laboral.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/alberto-parkel.jpg" alt="Alberto Parkel Fernández">
                        <h3>Alberto Parkel Fernández</h3>
                        <p><strong>Mentee</strong></p>
                        <p>Máster en Marketing</p>
                    </div>
                </div>


        
     
                <div class="slide">
                    <h5>Me ha encantado participar en el Programa Mentoring y espero volver a hacerlo en el futuro. Es muy enriquecedor compartir tu experiencia con jóvenes que están empezando en el mundo laboral. Sus preguntas y comentarios son una corriente de aire fresco que te lleva a una realidad diferente a la de tu día a día; te ayudan a pensar en cosas en las que habitualmente no reflexionas. ¡Muy recomendable!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/raquel-gonzalez.jpg" alt="Raquel González López">
                        <h3>Raquel González López</h3>
                        <p><strong>Mentora</strong></p>
                        <p>Responsable de Experiencia de Cliente</p>
                        <p>IBERDROLA, S.A.U. </p>
                    </div>
                </div>
               

            </slick>

        </div>

    </section>
</template>

<script>

import Slick from 'vue-slick';

import 'slick-carousel/slick/slick.css';

    export default {
        name : "slider-testimonios",
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots : true,
                    arrows : false,
                    adaptiveHeight: false
                },
            }
        },
        components: { Slick },
    }
</script>