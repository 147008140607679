<template>
    <section class="slider-pasos">


        <div class="container">
            <h2 class="subtitle">Pasos a seguir</h2>
            
            <nav class="pasos row-between">
                <button @click="goTo(n)" :active="active == n" v-for="(n,key) in 4" :key="key">{{n}}</button>

            </nav>

            <div class="slider">

                <slick ref="slick" :options="slickOptions" @afterChange="handleAfterChange">

                    <div class="slide slide-1">

                        <div class="slide-container row-between">


                            <div class="mentor">
                                <h3>Mentor/a</h3>
                                <img class="persona" src="/img/nidia-calleros.jpg" alt="Nidia calleros">
                                <button @click="showinfoMentor= !showinfoMentor" class="showinfo">{{(showinfoMentor) ?
                                    '-' : '+'}}</button>
                                <span @click="showinfoMentor= !showinfoMentor" class="requisitos-claim">Consulta los
                                    requisitos</span>
                                <a class="btn -skull -dark-orange" target="_blank" href="https://gestionmentoring.uc3m.es/login/mentor">Registrarme como mentor/a</a>
                                <div class="requisitos-list" v-if="showinfoMentor">
                                    <h3>Ser egresado/a UC3M</h3>
                                    <h3>Experiencia profesional de 3 años o superior</h3>
                                </div>
                            </div>

                            <div class="info column-center">

                                <h3>Accede al Programa Mentoring Alumni UC3M</h3>
                                <p>Comprueba que cumples los requisitos y date de alta</p>

                            </div>

                            <div class="mentee">
                                <h3>Mentee</h3>
                                <img class="persona" src="/img/alberto-parkel.jpg" alt="Alberto Parkel">
                                <button @click="showinfoMentee= !showinfoMentee" class="showinfo">{{(showinfoMentee) ?
                                    '-' : '+'}}</button>
                                <span @click="showinfoMentee= !showinfoMentee" class="requisitos-claim">Consulta los
                                    requisitos</span>
                                <a class="btn -skull -light-orange" target="_blank" href="https://gestionmentoring.uc3m.es/login/mentee">Registrarme como mentee</a>
                                <div class="requisitos-list" v-if="showinfoMentee">
                                    <h3>Estudiantes de grado, a partir de tercer curso, estudiantes de postgrado o egresados/as</h3>
                                    <h3>Cumplir con un mínimo de 3 sesiones en un máximo de 3 meses</h3>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="slide slide-2">

                        <div class="slide-container row-between">


                            <div class="mentor">
                                <h3>Mentor/a</h3>
                                <img class="persona" src="/img/rosa-puentes.jpg" alt="Rosa Puentes">
                                <p>Recibirás aviso del matching realizado con los datos de contacto del mentee que te ha seleccionado</p>
                            </div>

                            <div class="info column-center">

                                <h3>Matching entre
                                    mentor/a y mentee</h3>


                            </div>

                            <div class="mentee">
                                <h3>Mentee</h3>
                                <img class="persona" src="/img/ana-ahumada.jpg" alt="Ana Ahumada">
                                <p>Busca y selecciona un mentor/a (haz matching)</p>
                            </div>

                        </div>
                    </div>


                    <div class="slide slide-3">

                        <div class="slide-container row-between">


                            <div class="mentor">
                                <h3>Mentor/a</h3>
                                <img class="persona" src="/img/david-rubio.jpg" alt="Rosa Puentes">
                                <p>Contacta con tu mentee: establece el calendario de reuniones</p>
                                <p>Realiza reuniones periódicas (obligatorias 3 reuniones en 3 meses)</p>
                                <p>Intercambia información con tu mentee</p>
                            </div>

                            <div class="info column-center">

                                <h3>¡Inicia tu mentoría!</h3>


                            </div>

                            <div class="mentee">
                                <h3>Mentee</h3>
                                <img class="persona" src="/img/georgiana-livia.jpg" alt="Ana Ahumada">
                                <p>Ponte de acuerdo con tu mentor/a: establece objetivos y calendario de reuniones</p>
                                <p>Realiza reuniones periódicas (obligatorias 3 reuniones en 3 meses)</p>
                                <p>Intercambia información con tu mentor/a</p>
                            </div>

                        </div>
                    </div>


                    <div class="slide slide-4">

                        <div class="slide-container row-between">


                            <div class="mentor">
                                <h3>Mentor/a</h3>
                                <img class="persona" src="/img/sergio-campos.jpg" alt="Rosa Puentes">
                                <p>Evalúa tu experiencia</p>
                                <p>Y, por último, descarga el certificado de participación</p>
                                <a class="btn -skull -dark-orange" target="_blank" href="https://gestionmentoring.uc3m.es/login/mentor">Registrarme como mentor/a</a>
                            </div>

                            <div class="info column-center">

                                <h3>Fin de la mentoría a los 90 días</h3>


                            </div>

                            <div class="mentee">
                                <h3>Mentee</h3>
                                <img class="persona" src="/img/laura-ferre.jpg" alt="Ana Ahumada">
                                <p>Evalúa tu experiencia</p>
                                <p>Y, por último, descarga el certificado de participación</p>
                                <a class="btn -skull -light-orange" target="_blank" href="https://gestionmentoring.uc3m.es/login/mentee">Registrarme como mentee</a>
                            </div>

                        </div>
                    </div>

                </slick>

            </div>

        </div>

    </section>
</template>

<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: "slider-empresas",
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false,
                    infinite: false,
                    prevArrow: `<button type="button" class="slick-prev">Paso anterior</button>`,
                    nextArrow: `<button type="button" class="slick-next">Paso siguiente</button>`
                },
                showinfoMentee: false,
                showinfoMentor: false,
                active: "1"
            }
        },
        components: { Slick },
        methods: {
            handleAfterChange(event, slick, currentSlide) {
                this.active = currentSlide + 1;
            },
            goTo(to){
                this.$refs.slick.goTo(to-1);
            }
        }
    }
</script>