
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import translations from '@/translations';

let links = translations.es.enlaces;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Mentoring Alumni UC3M"
    }
  },
  {
    path: links.conocenos,
    name: 'conocenos',
    component: () => import(/* webpackChunkName: "conocenos" */ '../views/conocenos.vue'),
    meta: {
      title: "Conócenos"
    }
  },
  {
    path: links.sermentor,
    name: 'ser-mentor',
    component: () => import(/* webpackChunkName: "ser-mentor" */ '../views/ser-mentor.vue'),
    meta: {
      title: "Ser mentor"
    }
  },
  {
    path: links.encuentramentor,
    name: 'encuentra-un-mentor',
    component: () => import(/* webpackChunkName: "encuentra-un-mentor" */ '../views/encuentra-un-mentor.vue'),
    meta: {
      title: "Encuentra un mentor"
    }
  },
  {
    path: links.aulamentoring,
    name: 'aula-mentoring',
    component: () => import(/* webpackChunkName: "aula-mentoring" */ '../views/aula-mentoring.vue'),
    meta: {
      title: "Aula mentoring"
    }
  },
  {
    path: links.programamentoring,
    name: 'programa-mentoring',
    component: () => import(/* webpackChunkName: "programa-mentoring" */ '../views/programa-mentoring.vue'),
    meta: {
      title: "Programa Mentoring"
    }
  },
  {
    path: `${links.contacto}/:id?`,
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/contacto.vue'),
    meta: {
      title: "Contacto"
    }
  },
  {
    path: links.avisolegal,
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "aula-mentoring" */ '../views/aviso-legal.vue'),
    meta: {
      title: "Aviso legal",
      whitemenu : true
    }
  },
  {
    path: links.politicacookies,
    name: 'politica-cookies',
    component: () => import(/* webpackChunkName: "politica-cookies" */ '../views/politica-cookies.vue'),
    meta: {
      title: "Política de cookies",
      whitemenu : true
    }
  },
  {
    path: links.politicaprivacidad,
    name: 'politica-privacidad',
    component: () => import(/* webpackChunkName: "politica-privacidad" */ '../views/politica-privacidad.vue'),
    meta: {
      title: "Política de privacidad",
      whitemenu : true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  let exist = (!!to.meta.title);

  if(exist){
    document.title = to.meta.title
  }

  next()
});


export default router
