<template>

    <header class="menu" :min="minmenu" :white="$route.meta.whitemenu">

        <div class="container row-between">

            <router-link to="/" class="logo-container">
                <img class="logo" src="/img/logo-uc3m-alumni.png" alt="Uc3m Alumni">
            </router-link>

            <nav class="row-end" :show="showmenu">
                <router-link class="home" to="/">Home</router-link>
                <router-link :to="$t('enlaces.conocenos')">Conócenos</router-link>
                <router-link :to="$t('enlaces.programamentoring')">Programa mentoring</router-link>
                <router-link :to="$t('enlaces.sermentor')">Ser mentor/a</router-link>
                <router-link :to="$t('enlaces.encuentramentor')">Encuentra un mentor/a</router-link>
                <router-link :to="$t('enlaces.aulamentoring')">Aula mentoring</router-link>
                <router-link :to="$t('enlaces.contacto')">Contacto</router-link>
                <div class="areas row-end">
                    <a target="_blank" href="https://gestionmentoring.uc3m.es/login/mentor" class="btn-area -dark">Área mentores</a>
                    <a target="_blank" href="https://gestionmentoring.uc3m.es/login/mentee" class="btn-area -light">Área mentees</a>
                </div>
                <a @click="showmenu = false" class="close">✕</a>
            </nav>

            <button class="show-menu" @click="showmenu = true">
                <span></span>
                <span></span>
                <span></span>
            </button>



        </div>

    </header>

</template>


<script>
    export default {
        name : 'app-header',
        mounted(){
            window.addEventListener('scroll', () => {
                window.requestAnimationFrame(() => {
                this.checkmenu();    
                });
            });
        },
        data(){
            return {
                minmenu : false,
                showmenu : false
            }
        },
        methods : {
            checkmenu(){
                let pos = window.scrollY;
                this.minmenu = (pos >= 130);
            }
        },
        watch : {
            '$route'(){
                this.showmenu = false
            }
        }
    }
</script>