<template>
    <div class="contenedor-galeria">

        <div class="images-container row-center">
            <div class="image" v-for="(n,key) in 8" :key="key">
                <img :src="`/img/mosaicos/${id}/${n}-xs.jpg`" :alt="'Imagen-'+n" @click="setslider(n)">
            </div>
        </div>

        <div class="contenedor-slider" v-if="showslider">
            <button class="close" @click="showslider = false">✕</button>
            <div class="slider">
                <slick
                    ref="slickgaleria"
                    :options="slickOptions">
                    <div class="slide" v-for="(n,key) in 8" :key="key">
                        <img :src="`/img/mosaicos/${id}/${n}.jpg`" alt="">
                    </div>
                </slick>
            </div>
        </div>
    </div>
</template>

<script>

import Slick from 'vue-slick';

import 'slick-carousel/slick/slick.css';

    export default {
        name : "slider-galeria",
        props : ['id'],
        data() {
            return {
                showslider :false,
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots : false,
                    arrows : true,
                    adaptiveHeight: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                },
            }
        },
        components: { Slick },
        methods : {
            setslider(n){
                this.showslider = true;
                setTimeout(() => {
                    console.log(n)
                    this.$refs.slickgaleria.goTo(n-1);
                },200)
            }
        }
    }
</script>