export default {
	es: {
		enlaces : {
			conocenos : '/conocenos',
			programamentoring : '/programa-mentoring',
			sermentor : '/ser-mentor',
			encuentramentor : '/encuentra-mentor',
			aulamentoring : '/aula-mentoring',
			contacto : '/contacto',
			avisolegal : '/aviso-legal',
			politicaprivacidad : '/politica-privacidad',
			politicacookies : '/politica-cookies',
		}
	},
	en: {
	}
}
