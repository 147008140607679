<template>
  <div class="home">

    <section class="encabezado -home">

      <h1>Mentoring Alumni UC3M</h1>


      <div class="botones row-center">
        <router-link :to="$t('enlaces.sermentor')" class="btn -dark-orange">Quiero ser mentor/a</router-link>
        <router-link :to="$t('enlaces.encuentramentor')" class="btn -light-orange">Quiero ser mentee</router-link>
      </div>

    </section>

    <!-- <slider-empresas></slider-empresas> -->

    <section class="banda -center -gray">

      <div class="container column-center">

        <h2 class="title">Apoyando el desarrollo profesional</h2>

        <p>El Programa Mentoring es el espacio donde el ejemplo y la orientación de los alumni UC3M potencia al máximo
          el talento de sus estudiantes y egresados/as.</p>

        <p>Así, distintas generaciones comparten su conocimiento y refuerzan la idea de comunidad UC3M.</p>

        <router-link :to="$t('enlaces.programamentoring')" class="btn -dark-orange">Comienza tu experiencia Mentoring</router-link>

      </div>
    </section>


    <section class="tips-container">

      <h2 class="title">Beneficios del programa</h2>

      <div class="container -xs row-start ais">
        <article>
          <div class="icon">
            <img src="/img/icon-flexibilidad.svg" alt="Flexibilidad">
          </div>
          <h3>Aprendizaje</h3>
          <p>Transferencia de conocimientos a través de la experiencia de profesionales cualificados.
          </p>
        </article>
        <article>
          <div class="icon">
            <img src="/img/icon-networking.svg" alt="Networking">
          </div>
          <h3>Networking</h3>
          <p>Amplía tu red de contactos y genera nuevas oportunidades profesionales.</p>
        </article>
        <article>
          <div class="icon">
            <img src="/img/icon-acompanamiento.svg" alt="Acompañamiento">
          </div>
          <h3>Apoyo</h3>
          <p>En la definición de objetivos profesionales e identificación de facilitadores y barreras para su
            consecución.</p>
        </article>
        <article>
          <div class="icon">
            <img src="/img/icon-talleres.svg" alt="Talleres">
          </div>
          <h3>Talleres</h3>
          <p>Participación en talleres en el espacio Aula Mentoring.</p>
        </article>
        <article>
          <div class="icon">
            <img src="/img/icon-habilidades.svg" alt="Habilidades">
          </div>
          <h3>Habilidades</h3>
          <p>Desarrollo de habilidades profesionales.</p>
        </article>
        <article>
          <div class="icon">
            <img src="/img/icon-certificacion.svg" alt="Certificación">
          </div>
          <h3>Certificación</h3>
          <p>Reconocimiento de participación al superar las sesiones mínimas obligatorias.</p>
        </article>
      </div>
    </section>

    <slider-pasos></slider-pasos>

    <slider-testimonios></slider-testimonios>


    <section class="galeria">
      <div class="container column-center">
        <h2 class="title">Aula Mentoring</h2>
        <h3>Acercamos el mentoring grupal a las aulas</h3>
        <router-link :to="$t('enlaces.aulamentoring')" class="btn -skull">Más información</router-link>
      </div>
      <galeria :id="'home'"></galeria>

    </section>

  </div>
</template>

<script>

  // import sliderempresas from '@/components/slider-empresas';
  import slidertestimonios from '@/components/slider-testimonios-todos';
  import galeria from '@/components/galeria';
  import sliderpasos from '@/components/slider-pasos';

  export default {
    name: 'Home',
    data() {
      return {
      }
    },
    components: {
      // 'slider-empresas': sliderempresas,
      'slider-testimonios': slidertestimonios,
      'galeria': galeria,
      'slider-pasos': sliderpasos,
    }
  }
</script>

<style lang="scss" scoped>
  .banda p{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>