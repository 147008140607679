import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios';

let server = (location.hostname != 'mentoringalumni.uc3m.es') ? 'https://back-uc3m.dev.doubledot.es/' : 'https://gestionalumni.uc3m.es/' 

export default new Vuex.Store({
  state: {
    language: 'es',
    server : server
  },
  mutations: {
    null(){}
  },
  actions: {
    sendContacto({commit},data) {
      return new Promise((resolve) => {
        axios.post(`/api/contact/save`,data).then(response => {
          resolve(response.data.status);
          commit('null')
        });
      });
    }
  },
  modules: {
  }
})
