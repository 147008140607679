<template>
  <div id="app">
    <app-header></app-header>
    <router-view />
    <app-footer></app-footer>

    <a class="toup" @click="scrollup" v-if="showscrollup"></a>

  </div>
</template>


<script>

  import header from '@/components/app-header'
  import footer from '@/components/app-footer'

  export default {
    name: "App",
    mounted() {
      window.addEventListener("scroll", () => {
        if(window.scrollY > 300){
          this.showscrollup = true;
        }else{
          this.showscrollup = false;
        }
      });
    },
    data: () => ({
      showscrollup: false
    }),
    components: {
      'app-header': header,
      'app-footer': footer,
    },
    methods: {
      scrollup() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
  }

</script>

<style lang="scss">
  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/font-awesome.scss';
  @import '@/assets/css/responsive.scss';
</style>